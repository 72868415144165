<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title mb-0">Datos personales</h3>
          </div>
          <div class="card-body">
            <label for="name" class="form-control-label">
              Nombre<span class="required">*</span>
            </label>

            <input
                id="name"
                v-model="formModel.firstName"
                :class="[
                        'form-control',
                        v.firstName.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.firstName.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.firstName.$errors[0].$message }}
              </small>
            </p>
            <label for="lastname" class="form-control-label">
              Apellidos<span class="required">*</span>
            </label>

            <input
                id="lastname"
                v-model="formModel.lastName"
                :class="[
                        'form-control',
                        v.lastName.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.lastName.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.lastName.$errors[0].$message }}
              </small>
            </p>
            <label for="email" class="form-control-label">
              Email<span class="required">*</span>
            </label>

            <input
                id="email"
                v-model="formModel.email"
                :class="[
                        'form-control',
                        v.email.$error ? 'is-invalid' : '',
                      ]"
                type="email"
                placeholder=""
            />

            <p v-if="v.email.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.email.$errors[0].$message }}
              </small>
            </p>

            <label v-if="action === 'create' || isSuperAdmin()" for="password" class="form-control-label">
              <span>{{ action === 'create' ? 'Contraseña' : 'Reestablecer contraseña' }}</span>
              <span v-if="action === 'create'" class="required">*</span>
            </label>
            <input
                v-if="action === 'create' || isSuperAdmin()"
                id="password"
                v-model="formModel.plainPassword"
                :class="[ 'form-control', v.plainPassword?.$error ? 'is-invalid' : '' ]"
                type="password"
                placeholder=""
            />

            <p v-if="(action === 'create' || isSuperAdmin()) && v.plainPassword.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.plainPassword.$errors[0].$message }}
              </small>
            </p>

            <div class="row">
              <div class="col-md-6">
                <label for="gender" class="form-control-label w-100">
                  Género<span class="required">*</span>
                </label>
                <el-select
                    v-model="formModel.personalInfo.gender"
                    class="w-100"
                >
                  <el-option
                      v-for="selectItem in genders"
                      :key="selectItem.id"
                      :value="selectItem.id"
                      :label="selectItem.name"
                  />

                </el-select>
                <p v-if="v.personalInfo.gender.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.personalInfo.gender.$errors[0].$message }}
                  </small>
                </p>
              </div>
              <div class="col-md-6">
                <label for="date_of_birth" class="form-control-label">
                  Fecha de nacimiento<span class="required">*</span>
                </label>

                <el-date-picker
                    class="w-100 d-block mb-2"
                    v-model="formModel.personalInfo.dateOfBirth"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="2023/08/16"
                    :dayjs="Dayjs"
                />

                <p v-if="v.personalInfo.dateOfBirth.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.personalInfo.dateOfBirth.$errors[0].$message }}
                  </small>
                </p>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="dni" class="form-control-label">
                  DNI<span class="required">*</span>
                </label>
                <input
                    id="dni"
                    v-model="formModel.personalInfo.dni"
                    :class="[
                        'form-control',
                        v.personalInfo.dni.$error ? 'is-invalid' : '',
                      ]"
                    type="text"
                    placeholder=""
                />

                <p v-if="v.personalInfo.dni.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.personalInfo.dni.$errors[0].$message }}
                  </small>
                </p>
                <label for="situation" class="form-control-label w-100">
                  Situación familiar
                </label>
                <el-select
                    v-model="formModel.situation"
                    size="large"
                    class="w-100"
                >
                  <el-option
                      v-for="selectItem in situations"
                      :key="selectItem.id"
                      :value="selectItem['@id']"
                      :label="selectItem.name"
                  />
                </el-select>
                <p v-if="v.situation.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.situation.$errors[0].$message }}
                  </small>
                </p>
              </div>
              <div class="col-md-6">
                <label for="custody" class="form-control-label">
                  Ejercicio de la custodia
                </label>
                <el-select
                    v-model="formModel.custody"
                    class="w-100"
                    size="large"
                >
                  <el-option
                      v-for="selectItem in custodies"
                      :key="selectItem.id"
                      :value="selectItem['@id']"
                      :label="selectItem.name"
                  />
                </el-select>
                <p v-if="v.custody.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.custody.$errors[0].$message }}
                  </small>
                </p>
              <!--  <label for="ssn" class="form-control-label">
                  SSN<span class="required">*</span>
                </label>
                <input
                    id="ssn"
                    v-model="formModel.personalInfo.ssn"
                    :class="[
                        'form-control',
                        v.personalInfo.ssn.$error ? 'is-invalid' : '',
                      ]"
                    type="text"
                    placeholder=""
                />

                <p v-if="v.personalInfo.ssn.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.personalInfo.ssn.$errors[0].$message }}
                  </small>
                </p>-->
                <label for="chrelation" class="form-control-label w-100">
                  Parentesco
                </label>
                <el-select
                    v-model="formModel.childRelation"
                    size="large"
                    class="fw-100"
                >
                  <el-option
                      v-for="selectItem in relations"
                      :key="selectItem.id"
                      :value="selectItem['@id']"
                      :label="selectItem.name"
                  />
                </el-select>
                <p v-if="v.childRelation.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.childRelation.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
            <div class="row align-items-center">
               <div :class="entity.photoPath ? 'col-md-6' : 'col-md-12'">
                 <label for="photo" class="form-control-label">
                   Imagen
                 </label>
                 <input
                     type="file"
                     id="photo"
                     @change="uploadFile"
                     :class="[ 'form-control']"
                     placeholder=""
                 />
                 <p v-if="v.photo.$errors.length" class="invalid-feedback mb-0">
                   <small>
                     {{ v.photo.$errors[0].$message }}
                   </small>
                 </p>
               </div>
              <div class="col-md-6" v-if="entity.photoPath">
                <Avatar style="max-width: 100px;" :value="entity.photoPath" :size="45" />
              </div>
            </div>
            <template  v-if="isAdmin() || isSuperAdmin()">
              <label for="photo" class="form-control-label w-100">
                Puede ver la ficha de los menores
              </label>
              <el-switch v-model="formModel.accessChildInfo" />
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title mb-0">Dirección</h3>
          </div>
          <div class="card-body">
            <label for="address.street" class="form-control-label">
              Calle<span class="required">*</span>
            </label>
            <input
                id="address.street"
                v-model="formModel.address.street"
                :class="[
                        'form-control',
                        v.address.street.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.street.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.street.$errors[0].$message }}
              </small>
            </p>

            <label for="address.number" class="form-control-label">
              Número<span class="required">*</span>
            </label>

            <input
                id="address.number"
                v-model="formModel.address.number"
                :class="[
                        'form-control',
                        v.address.number.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.number.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.number.$errors[0].$message }}
              </small>
            </p>

            <label for="address.door" class="form-control-label">
              Puerta
            </label>

            <input
                id="address.number"
                v-model="formModel.address.door"
                :class="[
                        'form-control',
                        v.address.door.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.door.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.door.$errors[0].$message }}
              </small>
            </p>

            <label for="address.floor" class="form-control-label">
              Piso
            </label>

            <input
                id="address.floor"
                v-model="formModel.address.floor"
                :class="[
                        'form-control',
                        v.address.floor.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.floor.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.floor.$errors[0].$message }}
              </small>
            </p>

            <label for="address.ladder" class="form-control-label">
              Escalera
            </label>

            <input
                id="address.ladder"
                v-model="formModel.address.ladder"
                :class="[
                        'form-control',
                        v.address.ladder.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.ladder.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.ladder.$errors[0].$message }}
              </small>
            </p>

            <label for="address.zipCode" class="form-control-label">
              Código postal<span class="required">*</span>
            </label>

            <input
                id="address.zipCode"
                v-model="formModel.address.zipCode"
                :class="[
                        'form-control',
                        v.address.zipCode.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.zipCode.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.zipCode.$errors[0].$message }}
              </small>
            </p>
            <el-row :gutter="20">
              <el-col :span="12">
                <label for="province" class="form-control-label w-100">
                  Comunidad<span class="required">*</span>
                </label>
                <el-select  clearable  v-model="formModel.community" class="m-2 w-100" placeholder="Comunidad" size="large">
                  <el-option
                      v-for="selectItem in communities"
                      :key="selectItem.id"
                      :value="selectItem['@id']"
                      :label="selectItem.name"
                  />
                </el-select>
                <p v-if="v.community.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.community.$errors[0].$message }}
                  </small>
                </p>
                <template v-if="formModel.province">
                  <label for="locality" class="form-control-label w-100">
                    Localidad<span class="required">*</span>
                  </label>

                  <input
                      id="locality"
                      v-model="formModel.locality"
                      :class="[
                        'form-control',
                        v.locality.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                  />

                  <p v-if="v.locality.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.locality.$errors[0].$message }}
                    </small>
                  </p>

                </template>
              </el-col>
              <el-col :span="12">
                <template v-if="formModel.community">
                  <label for="province" class="form-control-label w-100">
                    Provincia<span class="required">*</span>
                  </label>
                  <el-select  clearable  v-model="formModel.province" class="m-2 w-100" placeholder="Provincia" size="large">
                    <el-option
                        v-for="selectItem in provinces"
                        :key="selectItem.id"
                        :value="selectItem['@id']"
                        :label="selectItem.name"
                    />
                  </el-select>
                  <p v-if="v.province.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.province.$errors[0].$message }}
                    </small>
                  </p>
                </template>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button
            type="submit"
            class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
          {{ buttonText }}
        </button>

        <button
            class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
            @click.prevent="handleCancel()"
        >
          Cancelar
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, maxLength, minLength, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {Avatar} from "@element-plus/icons-vue";
import {isAdmin, isParent, isSuperAdmin} from "@/services/authGuard";
import {genderList} from "@/utils/genders";


const store = useStore();
const router = useRouter();

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const provinces = computed(() => {
  let provinces = store.state.province.entities
  if(formModel.value.community){
    return provinces.filter(item =>{
      return item.community['@id'] === formModel.value.community
    })
  }
  return []
});
const communities = computed(() => store.state.community.entities);
const situations = computed(() => store.state.situation.entities);
const relations = computed(() => store.state.relation.entities);
const custodies = computed(() => store.state.custody.entities);
const formModel = ref({...props.entity}|| {});

const genders = ref(genderList)
const rules = computed(() => {
  let r = {
    firstName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    lastName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    email: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    personalInfo: {
      dni: {
        required: helpers.withMessage("Este campo es obligatorio", required),
        minLength: helpers.withMessage("Este campo es obligatorio", minLength(8)),
      },
      gender: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
      dateOfBirth: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      }
    },
    address: {
      street: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
      number: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
      door: {
       // required: helpers.withMessage("Este campo es obligatorio", required),
      },
      floor: {
        // required: helpers.withMessage("Este campo es obligatorio", required),
      },
      ladder: {
        // required: helpers.withMessage("Este campo es obligatorio", required),
      },
      zipCode: {
        required: helpers.withMessage("Este campo es obligatorio", required),
        minLength: helpers.withMessage("Este campo es obligatorio", minLength(5)),
        maxLength: helpers.withMessage("Este campo es obligatorio", maxLength(5)),
      },
    },
    community: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    province: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    locality: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    photo: {},
    childRelation: {},
    situation: {},
    custody: {},
    plainPassword: {}
  };
  if(props.action === 'create'){
    r.plainPassword = {
      required: helpers.withMessage("Este campo es obligatorio", required)
    }
  }
  return r
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
      formModel.value.name = null
      formModel.value.locality = null
      formModel.value.photo = null
      formModel.value.custody = null
      formModel.value.situation = null
      formModel.value.childRelation = null
};

onMounted(() => {
  store.dispatch("situation/getEntities");
  store.dispatch("relation/getEntities");
  store.dispatch("custody/getEntities");
  store.dispatch("province/getEntities");
  store.dispatch("community/getEntities");
  formModel.value.situation = props.entity.situation ? props.entity.situation['@id'] : null;
  formModel.value.childRelation = props.entity.childRelation ? props.entity.childRelation['@id'] : null;
  formModel.value.custody = props.entity.custody ? props.entity.custody['@id'] : null;
  if(props.entity.province){
    formModel.value.community = props.entity.province.community['@id'];
    formModel.value.province = props.entity.province['@id'];
  }
});

const handleCancel = () => {
  router.push({ name: 'Parents', params: {} });
};


const updateEntity = async (data) => {
  try {
    await store.dispatch("parent/edit", data).then(() =>{
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Familiar editado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      })
    });

    clearForm()
    await router.push({ name: !isParent() ? 'Parents' : 'Dashboard', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

const createEntity = async (data) => {
  try {
    await store.dispatch("parent/add", data).then(async (res) => {
      if (res.status && (res.status == 201 || res.status == 200)) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Familiar creado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        });
        await router.push({name: !isParent() ? 'Parents' : 'Dashboard', params: {}});
      }else{
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: res.response.data.detail,
          showConfirmButton: false,
          timer: 5000
        });
      }

    });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: error.detail,
      showConfirmButton: false,
      timer: 5000
    });
  }
};
const uploadFile = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = () => {
      formModel.value.photo = reader.result;
    };

    reader.readAsDataURL(file);
  }
}
</script>
