<template class="d-flex flex-column">
  <div class="d-flex flex-row justify-content-start items-center">
    <Avatar v-if="photoPath && isUser && message.senderParent.id !== store.getters['user/getUser'].id" :value="message.senderParent.photoPath" :size="45" />
    <Avatar v-if="!photoPath && !isUser && !message.administrator" value="" :size="45" />
  <!--  <span class="text-black" v-if="message.senderParent">{{ message.senderParent.fullName }}</span>
    <span class="text-black" v-else>{{ message.administrator.fullName }}</span> -->
    <div class="p-3 ms-3 text-box w-50" :class="alg">
      <p class="small mb-0">{{ message.text}}</p>
      <p class="small mb-0 text-bold mt-3" >
        {{ fullName}}
        <span class="small ms-3 mb-3 rounded-3 text-muted">
          {{ formatDate(message.createdAt)}}
        </span>
      </p>

    </div>
    <el-icon v-if="message.report" color="red" @click="showReport = !showReport"><info-filled></info-filled></el-icon>
    <img v-if="message.administrator" width="45" height="45"  class="logo-avatar" src="@/assets/img/logo-side.png" alt="">
    <Avatar style="margin-left: 5px;" v-if="!message.administrator && message.senderParent.id === store.getters['user/getUser'].id" :value="message.senderParent.photoPath" :size="45" />
    <el-dropdown v-if="(isParent() && !message.report) || isAdmin()">
        <el-icon class="el-icon--right"><arrow-down /></el-icon>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-if="isParent() && !message.report" @click="reportMessage()">Reportar</el-dropdown-item>
          <el-dropdown-item v-if="isAdmin()" @click="removeMessage()">Eliminar</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
   </div>
  <el-dialog class="dd" v-model="showReportForm" title="Reportar mensaje">
    <chat-report-form @close="showReportForm =false" @update-list="updateList" :entity="message" action="create"  />
  </el-dialog>
  <el-dialog class="dd" v-model="showReport" title="Mensaje reportado">
     <p>Este mensaje ha sido reportado por el siguiente motivo:</p>
    <p>{{ message.report.description }}</p>
  </el-dialog>
</template>

<script setup>
import moment from "moment/moment";
import {computed, defineProps, defineEmits, ref} from "vue";
import Avatar from "@/components/Avatar.vue";
import {useStore} from "vuex";
import {isAdmin, isParent} from "@/services/authGuard";
import {ArrowDown, InfoFilled} from "@element-plus/icons-vue";
import {ElMessageBox} from "element-plus";
import ChatReportForm from "@/views/chat/components/ChatReportForm.vue";

const store = useStore()
const emit = defineEmits(['update-list'])
const props = defineProps({
  message: {
    type: Object,
    required: true
  }
})
const showReportForm = ref(false)
const showReport = ref(false)
let photoPath = computed(() => {
  return props.message.senderParent ? props.message.senderParent.photoPath : null
});

let alg = computed(() => {
  return (props.message.senderParent && store.getters['user/getUser'].id ===   props.message.senderParent.id)
      || (props.message.administrator && store.getters['user/getUser'].id ===   props.message.administrator.id) ?
      'ml-auto' : ''
});
let isUser = computed(() => {
  return !!((props.message.senderParent && store.getters['user/getUser'].id === props.message.senderParent.id)
      || (props.message.administrator && store.getters['user/getUser'].id === props.message.administrator.id))
});
let fullName = computed(() => {
  return props.message.senderParent ? props.message.senderParent.fullName : props.message.administrator.fullName
});
const updateList = () => {
  emit('update-list')
}
const formatDate = (value) => {
  if (value) {
    return moment((value)).format('DD/MM/YYYY HH:mm')
  }

  return ''
};
const reportMessage = async () => {
  showReportForm.value = true
};
const deleteEntity = async () => {
  try {
    await store.dispatch("chat/deleteEntity", props.message.id);
    emit('update-list')
  } catch (error) {
    console.error(error);
  }
};
const removeMessage = () => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar el Mensaje?")
      .then(() => {
        deleteEntity();
      })
      .catch((error) => {
        console.error(error);
      });
}
</script>

<style scoped lang="scss">
   .text-box {
     border-radius: 15px;
     background-color: rgba(57, 192, 237,.2);
   }
   .message-avatar {
     width: 45px;
    // height: 100%;
   }
   .push-right {
     float: right!important;
   }
   .ml-auto {
     margin-left: auto!important;
   }
   .logo-avatar {
     margin-left: 10px;
     background-color: #493385;
     border-radius: 50%;
   }
</style>