<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6">
          <h3 class="card-title mb-0 d-inline-block">
            Eventos
          </h3>
        </div>
        <div class="col-md-6 text-end">
          <button @click="toggleView" class="btn btn-primary btn-sm border-1 text-white mb-0" style="margin-right: 15px;">ver como {{ viewText }}</button>
          <button @click="showEventForm = true" class="btn btn-primary btn-sm border-1 text-white mb-0">Crear evento</button>
          <a href="" @click.prevent="exportCalendar()"
             class="btn btn-primary btn-sm border-1 text-white mb-0" style="margin-left: 15px;">
            Exportar
          </a>
        </div>
      </div>


    </div>
    <div class="card-body">
      <Qalendar v-if="currentEntity && entities && view === 'calendario'"
                :events="entities"
                :config="config"
                @edit-event="showEventFormAction"
                @delete-event="showDeleteFormAction"
      />
      <div v-if="view === 'tabla'" class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th class="d-table-cell d-lg-none text-uppercase font-weight-bolder">
              Datos
            </th>
            <th class="d-lg-table-cell d-none text-uppercase font-weight-bolder">
              Fecha
            </th>
            <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
              Título
            </th>
            <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
              Lugar
            </th>
            <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
              Categoría
            </th>
            <td class="d-none d-lg-table-cell text-center"></td>
          </tr>
          </thead>

          <tbody>
          <tr v-for="item in entitiesNext" :key="item.id">
            <td class="d-none d-lg-table-cell">
            <!-- <div :style="{backgroundColor: item.color}" class="event-color"></div> {{ formatDate(item.dateTime) }} -->
              <div class="d-flex align-items-center gap-2">
                <div :style="{backgroundColor: item.color, marginBottom: '2px'}" class="event-color mt-0"></div>
                <span v-if="isEventLargerThanOneDay(item)">{{ formatDate(item.time.start) }} - {{ formatDate(item.time.end) }}</span>
                <span v-else>{{ formatDate(item.time.start) }}</span>
              </div>
            </td>
            <td>
              <div class="d-none d-lg-block wrap-balance">{{ item.title }}</div>
              <div class="d-lg-none d-table-block wrap-balance"> <b>Nombre: </b>{{ item.title }}</div>
              <div class="d-lg-none d-table-block wrap-balance"><b>Lugar: </b>{{ item.location }}</div>
              <div v-if="item.children.length > 0" class="d-lg-none d-table-block wrap-balance"><b>Hijos: </b><br>
                <div v-for="child in item.children" :key="child.id">{{child.fullName}}</div>
              </div>
              <div class="ml-3 d-lg-none d-table-block"><b>Categoría: </b>{{ item.category.name }}</div>
            </td>
            <td class="d-none d-lg-table-cell">
              {{ item.location }}
            </td>
            <td class="d-none d-lg-table-cell">
              {{ item.category.name }}
            </td>
            <td class="d-none d-lg-table-cell text-center">
              <router-link
                  class="btn btn-link text-dark mb-0 rounded icon-primary"
                  :to="{
                            name: 'Detalle-Event',
                            params: { id: item.id },
                          }"
              > <el-icon :size="25"
                         class="mr-2"
                         color="#fff">
                <View />
              </el-icon>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <el-dialog class="dd" v-model="showEventForm" title="Crear evento">
    <form @submit.prevent="handleSubmit" v-if="currentEntity">
      <div class="row">
        <div class="col">
          <label for="title" class="form-control-label">
            Título<span class="required">*</span>
          </label>
          <input
              id="title"
              v-model="formModel.title"
              :class="[
                        'form-control',
                        v.title.$error ? 'is-invalid' : '',
                      ]"
              type="text"
              placeholder=""
          />

          <p v-if="v.title.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.title.$errors[0].$message }}
            </small>
          </p>
          <label for="description" class="form-control-label">
            Descripción<span class="required">*</span>
          </label>

          <input
              id="description"
              v-model="formModel.description"
              :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
              type="text"
              placeholder=""
          />

          <p v-if="v.description.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.description.$errors[0].$message }}
            </small>
          </p>

          <label for="location" class="form-control-label">
            Lugar<span class="required">*</span>
          </label>

          <input
              id="location"
              v-model="formModel.location"
              :class="[
                        'form-control',
                        v.location.$error ? 'is-invalid' : '',
                      ]"
              type="text"
              placeholder=""
          />

          <p v-if="v.location.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.location.$errors[0].$message }}
            </small>
          </p>
          <div class="col-md-12">
            <label for="frequency" class="form-control-label w-100">
              Menores
            </label>
            <el-select
                v-model="formModel.children"
                size="large"
                class="w-100"
                multiple
            >
              <el-option
                  v-for="selectItem in currentEntity.children"
                  :key="selectItem.id"
                  :value="selectItem['@id']"
                  :label="selectItem.fullName"
              />
            </el-select>
            <p v-if="v.children.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.children.$errors[0].$message }}
              </small>
            </p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="category" class="form-control-label w-100">
                Categoría<span class="required">*</span>
              </label>
              <el-select
                  v-model="formModel.category"
                  size="large"
              >
                <el-option
                    v-for="selectItem in eventCategories"
                    :key="selectItem.id"
                    :value="selectItem['@id']"
                    :label="selectItem.name"
                />
              </el-select>
              <p v-if="v.category.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.category.$errors[0].$message }}
                </small>
              </p>
            </div>
            <div class="col-md-6">
               <label for="frequency" class="form-control-label w-100">
                 Frecuencia<span class="required">*</span>
               </label>
               <el-select
                   v-model="formModel.frequency"
                   size="large"
               >
                 <el-option
                     v-for="selectItem in frequencies"
                     :key="selectItem.id"
                     :value="selectItem.id"
                     :label="selectItem.name"
                     :disabled="isFrequencyDisabled(selectItem)"
                 />

               </el-select>
               <p v-if="v.frequency.$error" class="invalid-feedback mb-0">
                 <small>
                   {{ v.frequency.$errors[0].$message }}
                 </small>
               </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="datetime" class="form-control-label d-block mt-2">
                Fecha de inicio<span class="required">*</span>
              </label>
              <el-date-picker
                  id="datetime"
                  v-model="formModel.dateTime"
                  type="datetime"
                  value-format="YYYY-MM-DD HH:mm"
                  placeholder="2023/08/16"
                  :dayjs="Dayjs"
              />
              <p v-if="v.dateTime.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.dateTime.$errors[0].$message }}
                </small>
              </p>
            </div>
            <div class="col-md-4">
              <label for="endDateTime" class="form-control-label d-block mt-2">
                Fecha de fin<span class="required">*</span>
              </label>
              <el-date-picker
                  id="endDateTime"
                  v-model="formModel.endDateTime"
                  type="datetime"
                  value-format="YYYY-MM-DD HH:mm"
                  placeholder="2023/08/16"
                  :dayjs="Dayjs"
                  :picker-options="{ disabledDate: (time) => disabledEndDate(time) }"
              />
              <p v-if="v.endDateTime.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.endDateTime.$errors[0].$message }}
                </small>
              </p>
            </div>
            <div v-if="isRepeatedFrequency" class="col-md-4">
              <label for="endDateTime" class="form-control-label d-block mt-2">
                Repetir hasta<span class="required">*</span>
              </label>
              <el-date-picker
                  id="untilDate"
                  :type="formModel.frequency === 4 ? 'month' : 'date'"
                  v-model="formModel.untilDate"
                  value-format="YYYY-MM-DD"
                  placeholder="2023/08/16"
                  :dayjs="Dayjs"
              />
              <p v-if="v.untilDate.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.untilDate.$errors[0].$message }}
                </small>
              </p>
            </div>
          </div>
          <div class="mt-3">
            <button
                type="submit"
                class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
              {{ formModel.id ? 'Guardar' : 'Crear' }}
            </button>

            <button
                type="button"
                :disabled="sending"
                class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                @click.prevent="handleCancel()"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </el-dialog>
</template>

<script setup>
import {computed, defineProps, onMounted,defineEmits, ref} from "vue";
import { Qalendar } from "qalendar";
import moment from "moment";
import {useStore} from "vuex";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {View} from "@element-plus/icons-vue";
import {isAdmin, isParent, isSuperAdmin} from "@/services/authGuard";
import {ElMessageBox} from "element-plus";
import {clearErrors} from "@/utils/errorsUtils";
import {frequenciesList} from "@/utils/frequencies";

const props = defineProps({
  currentEntity: {
    type: Object,
    required: false
  }
});
const emit = defineEmits(['update-entity'])
const store = useStore();
const showEventForm = ref(false)
const view = ref("calendario")
const sending = ref(false)
const eventCategories = computed(() => store.state.eventCategory.entities.data);

const entitiesNext = computed(() => {
  const now = moment();
  return entities.value.filter(item => moment(item.time.start).isSameOrAfter(now));
});
const entities = computed(() => {
  let events = store.state.event.entities.data
  let adminEvents = store.state.administratorEvent.entities.data
  let res = []
  let ids = []
  if(events){
    events.map(item=> {
      res.push(item)
      ids.push(item.id)
    })
  }
  if(adminEvents) {
    adminEvents.map(item => {
      ids.push(item.id)
    })
  }
  // props.currentEntity.events.map(item => {
  //   if(item.frequency){
  //     let newItem = { ...item };
  //     newItem.time = { ...item.time}
  //     const year = new Date(newItem.time.end).getFullYear()
  //     const lastDayOnYear = moment(`${year}-12-31 23:59`)
  //
  //     if (item.frequency === 4) {
  //       const month = new Date(newItem.time.end).getMonth()
  //       for (let i = 1; i < 12 - month; i++) {
  //         const momentStartCopy = moment(newItem.time.start).add(i, 'months');
  //         const momentEndCopy = moment(newItem.time.end).add(i, 'months');
  //           const newEvent = { ...item };
  //           newEvent.time = {
  //             start: momentStartCopy.format('YYYY-MM-DD HH:mm'),
  //             end: momentEndCopy.format('YYYY-MM-DD HH:mm')
  //           };
  //           res.push(newEvent);
  //       }
  //     }
  //     if(item.frequency === 3){
  //       const momentCopy = moment(newItem.time.start);
  //       const duration = lastDayOnYear.diff(momentCopy, 'days');
  //
  //       for (let i = 14; i <= duration; i+=14) {
  //         const momentStartCopy = moment(newItem.time.start).add(i, 'days');
  //         const momentEndCopy = moment(newItem.time.end).add(i, 'days');
  //         const newEvent = { ...newItem };
  //         newEvent.time = {
  //           start: momentStartCopy.format('YYYY-MM-DD HH:mm'),
  //           end: momentEndCopy.format('YYYY-MM-DD HH:mm')
  //         };
  //         res.push(newEvent);
  //       }
  //     }
  //     if(item.frequency === 2){
  //       const momentCopy = moment(newItem.time.start);
  //       const duration = lastDayOnYear.diff(momentCopy, 'days');
  //
  //       for (let i = 7; i <= duration; i+=7) {
  //         const momentStartCopy = moment(newItem.time.start).add(i, 'days');
  //         const momentEndCopy = moment(newItem.time.end).add(i, 'days');
  //         const newEvent = { ...newItem };
  //         newEvent.time = {
  //           start: momentStartCopy.format('YYYY-MM-DD HH:mm'),
  //           end: momentEndCopy.format('YYYY-MM-DD HH:mm')
  //         };
  //         res.push(newEvent);
  //       }
  //     }
  //     if (item.frequency === 1) {
  //       const startDate = moment(newItem.time.start);
  //       const duration = lastDayOnYear.diff(startDate, 'days');
  //
  //       for (let i = 1; i <= duration; i++) {
  //         const momentStartCopy = moment(newItem.time.start).add(i, 'days');
  //         const momentEndCopy = moment(newItem.time.end).add(i, 'days');
  //         const newEvent = { ...newItem };
  //         newEvent.time = {
  //           start: momentStartCopy.format('YYYY-MM-DD HH:mm'),
  //           end: momentEndCopy.format('YYYY-MM-DD HH:mm')
  //         };
  //         res.push(newEvent);
  //
  //       }
  //     }
  //   }
  // })
  props.currentEntity.events.map(item => {
    if(item.frequency){
      let newItem = { ...item };
      newItem.time = { ...item.time}
      let untilDateFormatted, finalRepetitionDate
      if (item.untilDate) {
        untilDateFormatted = item.untilDate?.split('T')[0]
        finalRepetitionDate = moment(`${untilDateFormatted} 23:59`)
      }

      if (item.frequency === 4) {
        const month = parseInt(item.untilDate.substring(5, 7))

        for (let i = 1; i <= month; i++) {
          const momentStartCopy = moment(newItem.time.start).add(i, 'months');
          const momentEndCopy = moment(newItem.time.end).add(i, 'months');
            const newEvent = { ...item };
            newEvent.time = {
              start: momentStartCopy.format('YYYY-MM-DD HH:mm'),
              end: momentEndCopy.format('YYYY-MM-DD HH:mm')
            };
            res.push(newEvent);
        }
      }
      if(item.frequency === 3){
        const momentCopy = moment(newItem.time.end);
        const duration = finalRepetitionDate.diff(momentCopy, 'days');

        for (let i = 14; i <= duration; i+=14) {
          const momentStartCopy = moment(newItem.time.start).add(i, 'days');
          const momentEndCopy = moment(newItem.time.end).add(i, 'days');
          const newEvent = { ...newItem };
          newEvent.time = {
            start: momentStartCopy.format('YYYY-MM-DD HH:mm'),
            end: momentEndCopy.format('YYYY-MM-DD HH:mm')
          };
          res.push(newEvent);
        }
      }
      if(item.frequency === 2){
        const momentCopy = moment(newItem.time.end);
        const duration = finalRepetitionDate.diff(momentCopy, 'days');

        for (let i = 7; i <= duration; i+=7) {
          const momentStartCopy = moment(newItem.time.start).add(i, 'days');
          const momentEndCopy = moment(newItem.time.end).add(i, 'days');
          const newEvent = { ...newItem };
          newEvent.time = {
            start: momentStartCopy.format('YYYY-MM-DD HH:mm'),
            end: momentEndCopy.format('YYYY-MM-DD HH:mm')
          };
          res.push(newEvent);
        }
      }
      if (item.frequency === 1) {
        const startDate = moment(newItem.time.start);
        const duration = finalRepetitionDate.diff(startDate, 'days');

        for (let i = 1; i <= duration; i++) {
          const momentStartCopy = moment(newItem.time.start).add(i, 'days');
          const momentEndCopy = moment(newItem.time.end).add(i, 'days');
          const newEvent = { ...newItem };
          newEvent.time = {
            start: momentStartCopy.format('YYYY-MM-DD HH:mm'),
            end: momentEndCopy.format('YYYY-MM-DD HH:mm')
          };
          res.push(newEvent);

        }
      }
    }
  })
  return res.sort((a,b) => moment(a.time.start).diff(b.time.start, 'days'))
})

const viewText = computed(() => {
  if(view.value === 'calendario'){
    return 'tabla'
  }else{
    return 'calendario'
  }
});
const  config =ref( {
  locale: "es-ES",
  defaultMode: "month"
  })
const disabledEndDate = (date) => {
  // If departureDate then return valid dates after departureDate
  if (formModel.value.dateTime) {
    return date.getTime() < formModel.value.dateTime
  } else {
    // If !departureDate then return valid dates after today
    return date.getTime() < Date.now()
  }
}
const frequencies = ref(frequenciesList)

const formModel = ref( {
  title: null,
  category: null,
  description: null,
  dateTime: null,
  endDateTime: null,
  untilDate: null,
  location: null,
  frequency: null,
  administrator: store.getters['user/getUser']['@id'],
  expedient: props.currentEntity ? (props.currentEntity.expedient ? props.currentEntity.expedient['@id'] : props.currentEntity['@id']) : null
});
const isRepeatedFrequency = computed(() => formModel.value.frequency !== null)
const rules = computed(() => {
  const rules = {
    category: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    title: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    description: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    dateTime: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    endDateTime: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    location: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    frequency: {},
    children: {},
    untilDate: {},
  }
  if (isRepeatedFrequency.value) rules.untilDate = { required: helpers.withMessage("Este campo es obligatorio", required) }

  return rules
});

const isFrequencyDisabled = (freq) => {
  const end = moment(formModel.value?.endDateTime) ?? 0
  const start = moment(formModel.value?.dateTime) ?? 0
  const diff = end.diff(start, 'days')

  if (freq.name === 'Mensual') return diff > 27
  if (freq.name === 'Quincenal') return diff > 13
  if (freq.name === 'Semanal') return diff > 6
  if (freq.name === 'Diario') return diff > 0

  return false
}

const isEventLargerThanOneDay = (event) => {
  const start = moment(event.time.start)
  const end = moment(event.time.end)

  return end.diff(start, 'days') > 0
}

onMounted(() => {
  store.dispatch('eventCategory/getEntities')
  store.dispatch('administratorEvent/getEntities', {
    'expedient.id': props.currentEntity.id
  })
  store.dispatch( 'event/getEntities', {
    'expedient.id': props.currentEntity.id
  })
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const handleSubmit = () => {
  formModel.value.expedient = props.currentEntity.expedient ? props.currentEntity.expedient['@id'] : props.currentEntity['@id']
  if(isAdmin() || isSuperAdmin()){
    formModel.value.administrator = store.getters['user/getUser']['@id']
  }
  if(isParent()){
    formModel.value.parentUser = store.getters['user/getUser']['@id']
  }
  v$.value.$validate();

  if (!v$.value.$invalid) {
    formModel.value.id ? updateEvent(formModel.value) : createEvent(formModel.value)
  }
};

const handleCancel = () => {
  showEventForm.value= false
};

const createEvent = async (data) => {
  sending.value = true
  await store.dispatch(isAdmin() ? "administratorEvent/add" : "parentEvent/add", data).then(async (res) => {
    if (res.status && (res.status === 200 || res.status === 201)) {
      showEventForm.value = false
      emit('update-entity')
      await Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Evento creado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      });
      sending.value = false
    } else {
      await Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: clearErrors(res),
        showConfirmButton: false,
        timer: 3000
      });
      sending.value = false
    }
  });
}
const updateEvent = async (data) => {
  sending.value = true
  await store.dispatch(isAdmin() ? "administratorEvent/edit" : "parentEvent/edit", data).then(async (res) => {
    if (res.status && (res.status === 200 || res.status === 201)) {
      showEventForm.value = false
      emit('update-entity')
      await Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Evento editado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      });
      sending.value = false
    } else {
      await Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: clearErrors(res),
        showConfirmButton: false,
        timer: 3000
      });
      sending.value = false
    }
  });
}

const loading = ref(false)
const toggleView = () => {
  if(view.value === 'calendario'){
    view.value = 'tabla'
  }else{
    view.value = 'calendario'
  }
}
const formatDate = (value) => {
  if (value) {
    return moment((value)).format('DD/MM/YYYY')
  }

  return ''
};

const exportCalendar = async () => {
  if(loading.value) {
    return
  }
  loading.value = true
  await store.dispatch('event/exportCalendar', props.currentEntity.id) .then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    a.download = props.currentEntity.code+'_calendar.ics';
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
      .catch(error => {
        console.error('Error loading calendar file:', error);
      });
  loading.value = false
}

const showDeleteFormAction= async (id) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar el Evento?")
      .then(() => {
        deleteEntity(id);
      })
      .catch((error) => {
        console.error(error);
      });
}
const deleteEntity = async (id) => {
  try {
    await store.dispatch("event/deleteEntity", id).then(()=>{
      emit('update-entity')
    });
  } catch (error) {
    console.error(error);
  }
};
const showEventFormAction = async (id) => {
  if (isParent()) {
    await store.dispatch('parentEvent/getEntityById', id).then(() => {
      let event = store.state.parentEvent.currentEntity
      let children = []
      event.children.map(item=>{
        children.push(item["@id"]);
      })
      formModel.value = {
        id: event.id,
        title: event.title,
        category: event.category['@id'],
        description: event.description,
        dateTime: event.dateTime,
        endDateTime: event.endDateTime,
        untilDate: event.untilDate,
        location: event.location,
        children: children,
        frequency: event.frequency,
        expedient: event.expedient['@id']
      }
      showEventForm.value = true
    })
  }
  if (isAdmin()) {
    await store.dispatch('administratorEvent/getEntityById', id).then(() => {
      let event = store.state.administratorEvent.currentEntity
      let children = []
      event.children.map(item=>{
        children.push(item["@id"]);
      })
      formModel.value = {
        id: event.id,
        title: event.title,
        category: event.category['@id'],
        description: event.description,
        dateTime: event.dateTime,
        endDateTime: event.endDateTime,
        untilDate: event.untilDate,
        location: event.location,
        children: children,
        frequency: event.frequency,
        expedient: event.expedient['@id']
      }
      showEventForm.value = true

    })
  }
}
</script>

<style scoped lang="scss">
  .event-color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-top: 3px;
  }
</style>