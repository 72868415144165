<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="">
                <h3 class="card-title mb-0">
                  Expedientes
                </h3>

                <div class="sub-line"></div>
              </div>
              <div class="text-right flex flex-row gap-4 mt-2">
                 <a @click.prevent="showExpedientForm = true"
                    v-if="!isParent()"
                    class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto">
                    Nuevo Expediente
                  </a>

                  <a href=""  v-if="isTotal()" @click.prevent="exportToExcel()"
                     class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto">
                    Exportar
                  </a>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-12 py-2">
                <el-input
                    clearable
                    type="text"
                    placeholder="Hijo"
                    v-model="searchName"
                />
              </div>

              <div class="col-md-3">
                <button
                    @click="handleSearch"
                    class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border
                      hover:bg-blue-900 border-1
                      mt-2
                    ">
                  Buscar
                </button>
              </div>
            </div>
          </div>

          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0"  v-if="entities && entities.length >0">
                <thead>
                  <tr>
                    <th
                      class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Código
                    </th>
                    <th
                        class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Familiares
                    </th>
                    <th
                        class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Menores
                    </th>
                    <td class="d-none d-lg-table-cell text-center"></td>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in entities" :key="item.id">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            <router-link
                              :to="{
                                  name: 'Detalle-Expedient',
                                  params: {  id: item.id },
                                }"
                            >
                                {{item.code}}
                            </router-link>
                          </h6>
                            <div class="d-block d-lg-none">
                              <EditButton v-if="!isParent()" :id="item.id" route="Actualizar-Expedient" />
                              <DeleteButton v-if="!isParent()" @click="handleDelete( item.id)" />
                            </div>
                        </div>
                      </div>
                    </td>
                    <td v-if="!isAdmin()">
                      <router-link v-if="isAdmin()" :to="{name: 'Actualizar-Parent', params: {id: item.mainParent.id}}">
                        <el-tag type="primary" class="m-2">{{ item.mainParent.fullName }}</el-tag>
                      </router-link>
                      <el-tag type="primary" class="m-2" v-else>{{ item.mainParent.fullName }}</el-tag>
                      <router-link  v-if="isAdmin()"  :to="{name: 'Actualizar-Parent', params:{id: item.secondaryParent.id}}">
                        <el-tag type="primary">{{ item.secondaryParent.fullName }} </el-tag>
                      </router-link>
                      <el-tag type="primary" class="m-2" v-else>{{ item.secondaryParent.fullName }}</el-tag>
                    </td>
                    <td v-else>
                      <router-link :to="{name: 'Actualizar-Parent', params: {id: item.mainParent.id}}"> <el-tag type="info" class="m-2">{{ item.mainParent.fullName }}</el-tag> </router-link>
                      <router-link :to="{name: 'Actualizar-Parent', params:{id: item.secondaryParent.id}}"> <el-tag type="info">{{ item.secondaryParent.fullName }} </el-tag> </router-link>
                    </td>
                    <td v-if="isAdmin()">
                      <el-tag v-for="child in item.children" :key="child.id" type="primary" class="m-2">{{ child.fullName }}</el-tag>
                    </td>
                    <td v-else>
                      <el-tag v-for="child in item.children" :key="child.id" type="primary" class="m-2">{{ child.fullName }}</el-tag>
                    </td>
                    <td class="d-none d-lg-table-cell text-center">
                      <EditButton  v-if="!isParent()" :id="item.id" route="Actualizar-Expedient" />
                      <DeleteButton  v-if="!isParent()" @click="handleDelete( item.id)" />
                    </td>
                  </tr>
                </tbody>
              </table>

              <Pagination :pagination="store.state.expedient.pagination" entity="expedient" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog class="dd" v-model="showExpedientForm" title="Crear Expediente">
      <form @submit.prevent="handleExpedientSubmit">
        <div class="row">

          <div class="card mt-3">
            <div class="card-header white">
              <h3 class="card-title text-primary">Familiares</h3>
            </div>
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-md-6">
                  <label for="mainParent" class="form-control-label w-100">
                    Familiar<span class="required">*</span>
                  </label>
                  <el-select  clearable  v-model="formModel.mainParent" class="m-2" placeholder="Familiar" size="large">
                    <el-option
                        v-for="selectItem in parents"
                        :key="selectItem.id"
                        :value="selectItem['@id']"
                        :label="selectItem.fullName"
                    />
                  </el-select>
                  <button class="btn btn-primary d-block mt-2 btn-sm" @click.prevent="showParentForm = true; activeParent='main';">Crear nuevo</button>
                  <p v-if="v.mainParent.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.mainParent.$errors[0].$message }}
                    </small>
                  </p>
                </div>
                <div class="col-md-6">
                  <label for="secondaryParent" class="form-control-label w-100">
                    Familiar<span class="required">*</span>
                  </label>
                  <el-select  clearable  v-model="formModel.secondaryParent" class="m-2" placeholder="Familiar" size="large">
                    <el-option
                        v-for="selectItem in parents"
                        :key="selectItem.id"
                        :value="selectItem['@id']"
                        :label="selectItem.fullName"
                    />
                  </el-select>
                  <button class="btn btn-primary d-block mt-2 btn-sm" @click.prevent="showParentForm = true; activeParent='secondary';">Crear nuevo</button>
                  <p v-if="v.secondaryParent.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.secondaryParent.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="row" v-if="isSuperAdmin()">
                <div class="col-md-6">
                  <label for="mainParent" class="form-control-label w-100">
                    Profesional<span class="required">*</span>
                  </label>
                  <el-select  clearable
                              v-model="formModel.administrators"
                              class="m-2"
                              multiple=""
                              placeholder="Profesional"
                              size="large">
                    <el-option
                        v-for="selectItem in store.state.user.entities.data"
                        :key="selectItem.id"
                        :value="selectItem.id"
                        :label="selectItem.fullName"
                    />
                  </el-select>
                  <p v-if="v.administrators.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.administrators.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button
                type="submit"
                class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
              Crear
            </button>

            <button
                class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                @click="handleCancel()"
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>
    </el-dialog>
    <el-dialog class="dd" v-model="showParentForm" title="Crear Familiar">
      <parent-form action="create"  @close-form="handleCancelSubForms" @add-parent="addParent" />
    </el-dialog>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import {ElLoading, ElMessageBox} from "element-plus";
import { ref } from "vue";
import {isAdmin, isParent, isTotal} from "@/services/authGuard";
import ParentForm from "@/views/dashboard/ParentForm.vue";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {useRouter} from "vue-router";
import Pagination from "@/components/Pagination.vue";
import EditButton from "@/components/EditButton.vue";
import DeleteButton from "@/components/DeleteButton.vue";
import {clearErrors} from "@/utils/errorsUtils";
import {isSuperAdmin} from "../../services/authGuard";


const store = useStore();
const searchName = ref("");
const showExpedientForm = ref(false);
const showParentForm = ref(false);
const parents = computed(() => store.state.parent.entities.data);
const activeParent = ref('main')
const router  = useRouter();


const formModel = ref( {
  code: '0123456789',
  child: {
    firstName: null,
    lastName: null,
    personalInfo: {
      gender:  null,
      dateOfBirth: null,
      ssn: null,
      dni: null,
    },
    address: {
      number: null,
      street: null,
      ladder: null,
      door: null,
      floor: null
    }
  },
  city: null,
  photo: null,
  administrators: isSuperAdmin() ? null : [store.getters['user/getUser'].id]
});

const addParent = (id) => {
  store.dispatch("parent/getEntities");
  if(activeParent.value === 'main'){
    formModel.value.mainParent = id
  }else{
    formModel.value.secondaryParent = id
  }
}
const rules = computed(() => {
  return {
      mainParent: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
      secondaryParent: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
    administrators: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
  };
});

const clearForm = () => {
  formModel.value = {}
};
const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const handleCancelSubForms = () => {
  showParentForm.value = false
};
const handleCancel = () => {
  showExpedientForm.value = false
};

const handleSearch = () => {
  store.dispatch("expedient/getEntities", {
    name: searchName.value,
  });
};
const createExpedient = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: 'Enviando',
    background: 'rgba(0, 0, 0, 0.7)',
  })
    await store.dispatch("expedient/add", data).then(async (res) => {
      if (res.status === 200 || res.status === 201) {
        showExpedientForm.value = false
        spin.close()
        clearForm()
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Expediente creado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
        await router.push({name: 'Dashboard', params: {}});
      } else {
        showExpedientForm.value = false
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }
    })
  spin.close()
};
const handleExpedientSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    createExpedient( formModel.value);
  }
};
const getExpedients = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  if(isAdmin()){
     await store.dispatch("administratorExpedient/getEntities", {
      "administrator.id": store.getters['user/getUser'].id
    })
  }
  await store.dispatch("expedient/getEntities")
  spin.close()
}
const getParents = async () => {
  await store.dispatch("parent/getEntities");
};

const getAdmins = async () => {
  await store.dispatch("user/getAdmins");
};
onMounted(async () => {
  await getExpedients();
  await getParents();
  await getAdmins()
});

const entities = computed(
  () => {
    return  store.state.expedient.entities.data
  }
);

const deleteEntity = async (position) => {
  try {
    await store.dispatch("expedient/deleteEntity", position);
    await getExpedients();
  } catch (error) {
    console.error(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar el Expediente?")
    .then(() => {
      deleteEntity(item);
    })
    .catch((error) => {
      console.error(error);
    });
};

const exportToExcel = async () => {
  await store.dispatch('expedient/exportList') .then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    a.download = 'expedientes.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
      .catch(error => {
        console.error('Error loading Excel:', error);
      });
}

</script>
