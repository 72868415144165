<template>
    {{ formatDate(value)}}
</template>

<script setup>
import { defineProps } from "vue";
import moment from "moment/moment";

defineProps({
  value: {
    type: String,
    required: true
  }
})
const formatDate = (value) => {
  if (value) {
    return moment((value)).format('DD/MM/YYYY')
  }

  return ''
};
</script>

<style scoped>

</style>