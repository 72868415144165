<template>
   <div class="card">
     <div class="card-header">
       <div class="row">
         <div class="col-md-6">
           <h3 class="card-title mb-0 d-inline-block">
             Próximos eventos
           </h3>
         </div>
         <div class="col-md-6 text-end">
           <button @click="toggleView" class="btn btn-primary btn-sm border-1 text-white mb-0" style="margin-right: 15px;">ver como {{ viewText }}</button>
         </div>
       </div>
     </div>
     <div class="row">
       <div class="col-md-3 col-12 py-2" style="margin-left: 25px;">
         <el-date-picker
             v-model="searchDate"
             type="date"
             value-format="YYYY-MM-DD"
             placeholder="2023/08/16"
             :disabled-date="disabledDate"
             :shortcuts="shortcuts"
             :dayjs="Dayjs"
         />
       </div>
     </div>
     <div class="card-body">
       <Qalendar v-if="entities && view === 'calendario'"
                 :events="entities"
                 :config="config"
       />
       <div v-if="view === 'tabla'" class="table-responsive p-0">
         <table class="table align-items-center mb-0">
           <thead>
           <tr>
             <th class="d-table-cell d-lg-none text-uppercase font-weight-bolder">
               Datos
             </th>
             <th class="d-lg-table-cell d-none text-uppercase font-weight-bolder">
               Fecha
             </th>
             <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
               Título
             </th>
             <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
               Lugar
             </th>
             <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
               Categoría
             </th>
             <td class="d-none d-lg-table-cell text-center"></td>
           </tr>
           </thead>

           <tbody>
           <tr v-for="item in entities" :key="item.id">
             <td class="d-none d-lg-table-cell">
               <!-- <div :style="{backgroundColor: item.color}" class="event-color"></div> {{ formatDate(item.dateTime) }} -->
               <div class="d-flex align-items-center gap-2">
                 <div :style="{backgroundColor: item.color, marginBottom: '2px'}" class="event-color mt-0"></div>
                 <span v-if="isEventLargerThanOneDay(item)">{{ formatDate(item.time.start) }} - {{ formatDate(item.time.end) }}</span>
                 <span v-else>{{ formatDate(item.time.start) }}</span>
               </div>
             </td>
             <td>
               <div class="d-none d-lg-block wrap-balance">{{ item.title }}</div>
               <div class="d-lg-none d-table-block wrap-balance"> <b>Nombre: </b>{{ item.title }}</div>
               <div class="d-lg-none d-table-block wrap-balance"><b>Lugar: </b>{{ item.location }}</div>
               <div v-if="item.children.length > 0" class="d-lg-none d-table-block wrap-balance"><b>Hijos: </b><br>
                 <div v-for="child in item.children" :key="child.id">{{child.fullName}}</div>
               </div>
               <div class="ml-3 d-lg-none d-table-block"><b>Categoría: </b>{{ item.category.name }}</div>
             </td>
             <td class="d-none d-lg-table-cell">
               {{ item.location }}
             </td>
             <td class="d-none d-lg-table-cell">
               {{ item.category.name }}
             </td>
             <td class="d-none d-lg-table-cell text-center">
               <router-link
                   class="btn btn-link text-dark mb-0 rounded icon-primary"
                   :to="{
                            name: 'Detalle-Event',
                            params: { id: item.id },
                          }"
               > <el-icon :size="25"
                          class="mr-2"
                          color="#fff">
                 <View />
               </el-icon>
               </router-link>
             </td>
           </tr>
           </tbody>
         </table>
       </div>
     </div>
   </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { ref, watch } from 'vue'
const store = useStore();
const searchDate = ref(new Date());
import { Qalendar } from "qalendar";
import {View} from "@element-plus/icons-vue";
import 'dayjs/locale/en';
import moment from "moment";
const view = ref("calendario")
const viewText = computed(() => {
  if(view.value === 'calendario'){
    return 'tabla'
  }else{
    return 'calendario'
  }
});
const getEvents = async () => {
   await store.dispatch("administratorEvent/getEntities", {
    "administrator.id": store.getters['user/getUser'].id,
    "dateTime[after]": searchDate.value
  });
};
const getExpedients = async () => {
  await store.dispatch("expedient/getEntities");
};
const colorScheme = computed(() => store.state.eventCategory.scheme);
const  config = computed(() => {
  return {
    locale: "es-ES",
    defaultMode: "month",
    style: {
      colorSchemes: colorScheme
    }
  }
});
onMounted(async () => {
  await getExpedients()
  await getEvents()
})
const isEventLargerThanOneDay = (event) => {
  const start = moment(event.time.start)
  const end = moment(event.time.end)

  return end.diff(start, 'days') > 0
}
const formatDate = (value) => {
  if (value) {
    return moment((value)).format('DD/MM/YYYY')
  }

  return ''
};
const toggleView = () => {
  if(view.value === 'calendario'){
    view.value = 'tabla'
  }else{
    view.value = 'calendario'
  }
}
const entities = computed(
    () => {
      let events = []
      if(store.state.administratorEvent.entities.data){
        store.state.administratorEvent.entities.data.map(item=>{
          if(!item.events) return
          item.events.map(e=>{
            const myDate = moment(e.dateTime.split('T')[0]);  // Replace '2023-01-01' with your desired date
            const currentDate = moment();

            const difference = currentDate.diff(myDate, 'days');
            console.log(difference)
            if(difference <=0){
              events.push(e)
            }

          })
        })
      }

      return events
    }
);
const handleSearch = () => {
  getEvents();
};
watch(searchDate, async () => {
   handleSearch()
})
</script>

<style scoped>
.event-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 3px;
}
</style>