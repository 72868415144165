<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6">
          <h3 class="card-title mb-0 d-inline-block">
            Eventos
          </h3>
        </div>
        <div class="col-md-6 text-end">
          <button @click="toggleView" class="btn btn-primary btn-sm border-1 text-white mb-0" style="margin-right: 15px;">ver como {{ viewText }}</button>
          <button @click="showEventForm = true" class="btn btn-primary btn-sm border-1 text-white mb-0">Crear evento</button>
        </div>
      </div>


    </div>
    <div class="card-body">
      <div v-if="loading">Creando eventos</div>
      <Qalendar v-if="events && view === 'calendario' && !loading"
                :events="events"
                :config="config"
                @edit-event="showEventFormAction"
                @delete-event="showDeleteFormAction"
      />
      <div v-if="view === 'tabla' && !loading" class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th class="d-table-cell d-lg-none text-uppercase font-weight-bolder">
              Datos
            </th>
            <th class="d-lg-table-cell d-none text-uppercase font-weight-bolder">
              Fecha
            </th>
            <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
              Título
            </th>
            <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
              Lugar
            </th>
            <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
              Categoría
            </th>
            <td class="d-none d-lg-table-cell text-center"></td>
          </tr>
          </thead>

          <tbody>
          <tr v-for="item in events" :key="item.id">
            <td class="d-none d-lg-table-cell">
              {{ formatDate(item.dateTime) }}
            </td>
            <td>
              <div class="d-none d-lg-block wrap-balance">{{ item.title }}</div>
              <div class="d-lg-none d-table-block wrap-balance"> <b>Nombre: </b>{{ item.title }}</div>
              <div class="d-lg-none d-table-block wrap-balance"><b>Lugar: </b>{{ item.location }}</div>
              <div v-if="item.children.length > 0" class="d-lg-none d-table-block wrap-balance"><b>Hijos: </b><br>
                <div v-for="child in item.children" :key="child.id">{{child.fullName}}</div>
              </div>
              <div class="ml-3 d-lg-none d-table-block"><b>Categoría: </b>{{ item.category.name }}</div>
            </td>
            <td class="d-none d-lg-table-cell">
              {{ item.location }}
            </td>
            <td class="d-none d-lg-table-cell">
              {{ item.category.name }}
            </td>
            <td class="d-none d-lg-table-cell text-center">
              <router-link
                  class="btn btn-link text-dark mb-0 rounded icon-primary"
                  :to="{
                            name: 'Detalle-Event',
                            params: { id: item.id },
                          }"
              > <el-icon :size="25"
                         class="mr-2"
                         color="#fff">
                <View />
              </el-icon>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <el-dialog class="dd" v-model="showEventForm" title="Crear evento">
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col">
          <label for="title" class="form-control-label">
            Título<span class="required">*</span>
          </label>
          <input
              id="title"
              v-model="formModel.title"
              :class="[
                        'form-control',
                        v.title.$error ? 'is-invalid' : '',
                      ]"
              type="text"
              placeholder=""
          />

          <p v-if="v.title.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.title.$errors[0].$message }}
            </small>
          </p>
          <label for="description" class="form-control-label">
            Descripción<span class="required">*</span>
          </label>

          <input
              id="description"
              v-model="formModel.description"
              :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
              type="text"
              placeholder=""
          />

          <p v-if="v.description.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.description.$errors[0].$message }}
            </small>
          </p>

          <label for="location" class="form-control-label">
            Lugar<span class="required">*</span>
          </label>

          <input
              id="location"
              v-model="formModel.location"
              :class="[
                        'form-control',
                        v.location.$error ? 'is-invalid' : '',
                      ]"
              type="text"
              placeholder=""
          />

          <p v-if="v.location.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.location.$errors[0].$message }}
            </small>
          </p>
          <div class="col-md-12">
            <label for="frequency" class="form-control-label w-100">
              Menores
            </label>
            <el-select
                v-model="formModel.children"
                size="large"
                class="w-100"
                multiple
            >
              <el-option
                  v-for="selectItem in currentEntity.children"
                  :key="selectItem.id"
                  :value="selectItem['@id']"
                  :label="selectItem.fullName"
              />
            </el-select>
            <p v-if="v.children.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.children.$errors[0].$message }}
              </small>
            </p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="category" class="form-control-label w-100">
                Categoría<span class="required">*</span>
              </label>
              <el-select
                  v-model="formModel.category"
                  size="large"
              >
                <el-option
                    v-for="selectItem in eventCategories"
                    :key="selectItem.id"
                    :value="selectItem['@id']"
                    :label="selectItem.name"
                />
              </el-select>
              <p v-if="v.category.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.category.$errors[0].$message }}
                </small>
              </p>
            </div>
            <div class="col-md-6">
              <label for="frequency" class="form-control-label w-100">
                Frecuencia<span class="required">*</span>
              </label>
              <el-select
                  v-model="formModel.frequency"
                  size="large"
              >
                <el-option
                    v-for="selectItem in frequencies"
                    :key="selectItem.id"
                    :value="selectItem.id"
                    :label="selectItem.name"
                />

              </el-select>
              <p v-if="v.frequency.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.frequency.$errors[0].$message }}
                </small>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="datetime" class="form-control-label d-block mt-2">
                Fecha de inicio<span class="required">*</span>
              </label>
              <el-date-picker
                  id="datetime"
                  v-model="formModel.dateTime"
                  type="datetime"
                  value-format="YYYY-MM-DD HH:mm"
                  placeholder="2023/08/16"
                  :dayjs="Dayjs"
              />
              <p v-if="v.dateTime.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.dateTime.$errors[0].$message }}
                </small>
              </p>
            </div>
            <div class="col-md-6">
              <label for="endDateTime" class="form-control-label d-block mt-2">
                Fecha de fin<span class="required">*</span>
              </label>
              <el-date-picker
                  id="endDateTime"
                  v-model="formModel.endDateTime"
                  type="datetime"
                  value-format="YYYY-MM-DD HH:mm"
                  placeholder="2023/08/16"
                  :dayjs="Dayjs"
                  :picker-options="{ disabledDate: (time) => disabledEndDate(time) }"
              />
              <p v-if="v.endDateTime.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.endDateTime.$errors[0].$message }}
                </small>
              </p>
            </div>
          </div>
          <div class="mt-3">
            <button
                type="submit"
                class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
              Crear
            </button>

            <button
                type="button"
                class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                @click.prevent="handleCancel()"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </el-dialog>
</template>

<script setup>
import {computed, defineProps, onMounted,defineEmits, ref} from "vue";
import { Qalendar } from "qalendar";
import moment from "moment";
import {useStore} from "vuex";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {View} from "@element-plus/icons-vue";
import {isAdmin, isParent} from "@/services/authGuard";
import {ElLoading, ElMessageBox} from "element-plus";
import {clearErrors} from "@/utils/errorsUtils";
import {frequenciesList} from "@/utils/frequencies";

const props = defineProps({
  currentEntity: {
    type: Object,
    required: false
  },
  events: {
    type: Array,
    required: false,
    default: () => []
  }
});
const emit = defineEmits(['update-entity'])
const store = useStore();
const showEventForm = ref(false)
const view = ref("calendario")
const eventCategories = computed(() => store.state.eventCategory.entities.data);
const colorScheme = computed(() => store.state.eventCategory.scheme);
const viewText = computed(() => {
  if(view.value === 'calendario'){
    return 'tabla'
  }else{
    return 'calendario'
  }
});
const  config = computed(() => {
   return {
     locale: "es-ES",
     defaultMode: "month",
     style: {
       colorSchemes: colorScheme
     }
   }
});
const disabledEndDate = (date) => {
  // If departureDate then return valid dates after departureDate
  if (formModel.value.dateTime) {
    return date.getTime() < formModel.value.dateTime
  } else {
    // If !departureDate then return valid dates after today
    return date.getTime() < Date.now()
  }
}
const frequencies = ref(frequenciesList)

const formModel = ref( {
  title: null,
  category: null,
  description: null,
  dateTime: null,
  endDateTime: null,
  location: null,
  children: [],
  frequency: null,
  expedient: props.currentEntity ? (props.currentEntity.expedient ? props.currentEntity.expedient.id : props.currentEntity.id) : null
});
const loading = computed(() => isAdmin() ? store.state.administratorExpedient.loading : store.state.expedient.loading);
const rules = computed(() => {
  return {
    category: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    title: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    description: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    dateTime: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    endDateTime: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    location: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    frequency: {

    },
    children: {

    },
  };
});

onMounted(() => {
  store.dispatch('eventCategory/getEntities')
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const showDeleteFormAction= async (id) => {
    ElMessageBox.confirm("¿Está seguro que deseas eliminar el Evento?")
        .then(() => {
          deleteEntity(id);
        })
        .catch((error) => {
          console.error(error);
        });
}
const deleteEntity = async (id) => {
  try {
    await store.dispatch("event/deleteEntity", id).then(()=>{
      emit('update-entity')
    });
  } catch (error) {
    console.error(error);
  }
};
const showEventFormAction = async (id) => {
  if (isParent()) {
    await store.dispatch('parentEvent/getEntityById', id).then(() => {
      let event = store.state.parentEvent.currentEntity
      let children = []
      event.children.map(item=>{
         children.push(item["@id"]);
      })
      formModel.value = {
        id: event.id,
        title: event.title,
        category: event.category['@id'],
        description: event.description,
        dateTime: event.dateTime,
        endDateTime: event.endDateTime,
        location: event.location,
        children: children,
        frequency: event.frequency,
        expedient: event.expedient['@id']
      }
      showEventForm.value = true
    })
  }
  if (isAdmin()) {
    await store.dispatch('administratorEvent/getEntityById', id).then(() => {
      let event = store.state.administratorEvent.currentEntity
      let children = []
      event.children.map(item=>{
        children.push(item["@id"]);
      })
        formModel.value = {
          id: event.id,
          title: event.title,
          category: event.category['@id'],
          description: event.description,
          dateTime: event.dateTime,
          endDateTime: event.endDateTime,
          location: event.location,
          children: children,
          frequency: event.frequency,
          expedient: event.expedient['@id']
        }
        showEventForm.value = true

    })
  }
}
const clearForm = () => {
  formModel.value.title = null
  formModel.value.category = null
  formModel.value.dateTime = null
  formModel.value.children = []
  formModel.value.endDateTime = null
  formModel.value.location = null
  formModel.value.description = null
  formModel.value.frequency = null
  formModel.value.description = null
  v.$reset()
};
const handleSubmit = () => {
  if(isAdmin()){
    formModel.value.administrator = store.getters['user/getUser']['@id']
  }
  if(isParent()){
    formModel.value.parentUser = store.getters['user/getUser']['@id']
  }
  formModel.value.expedient = props.currentEntity.expedient ? props.currentEntity.expedient['@id'] : props.currentEntity['@id']
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if(formModel.value.id){
      updateEvent( formModel.value);
    }else{
      createEvent( formModel.value);
    }

  }
};

const handleCancel = () => {
  showEventForm.value= false
};

const createEvent = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: 'Creando',
    background: 'rgba(0, 0, 0, 0.7)',
  })
    await store.dispatch(isAdmin() ? "administratorEvent/add" : "parentEvent/add", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        spin.close()
        showEventForm.value = false
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Evento creado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        });
        clearForm()
        emit('update-entity')
      } else {
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }

    });
};
const updateEvent = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: 'Creando',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch(isAdmin() ? "administratorEvent/edit" : "parentEvent/edit", data).then(async (res) => {
    if (res.status && (res.status === 200 || res.status === 201)) {
      spin.close()
      showEventForm.value = false
      clearForm()
      await Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Evento editado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      });
      emit('update-entity')
    } else {
      spin.close()
      await Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Se ha producido un error.',
        showConfirmButton: false,
        timer: 3000
      });
    }

  });
};
const toggleView = () => {
  if(view.value === 'calendario'){
    view.value = 'tabla'
  }else{
    view.value = 'calendario'
  }
}
const formatDate = (value) => {
  if (value) {
    return moment((value)).format('DD/MM/YYYY')
  }

  return ''
};
</script>

<style scoped>

</style>