<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="child" class="form-control-label w-100">
          Hijos<span class="required">*</span>
        </label>
        <el-select
            v-model="formModel.children"
            size="large"
            multiple
        >
          <el-option
              v-for="selectItem in children"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.fullName"
          />
        </el-select>
        <p v-if="v.children.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.children.$errors[0].$message }}
          </small>
        </p>

        <label for="mainParent" class="form-control-label w-100">
          Familiar<span class="required">*</span>
        </label>
        <el-select
            v-model="formModel.mainParent"
            size="large"
        >
          <el-option
              v-for="selectItem in parents"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.fullName"
          />
        </el-select>
        <p v-if="v.mainParent.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.mainParent.$errors[0].$message }}
          </small>
        </p>
        <label for="secondaryParent" class="form-control-label w-100">
          Familiar<span class="required">*</span>
        </label>
        <el-select
            cleareble
            v-model="formModel.secondaryParent"
            size="large"
        >
          <el-option
              v-for="selectItem in parents"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.fullName"
          />
        </el-select>
        <p v-if="v.secondaryParent.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.secondaryParent.$errors[0].$message }}
          </small>
        </p>
    </div>
      <div class="col-md-6">
        <label for="chat_locked" class="form-control-label">
          Chat bloqueado
        </label><br>
        <el-switch id="chat_locked" v-model="formModel.chat_locked" />
      </div>
    <div class="mt-3">
      <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        {{ buttonText }}
      </button>

      <button
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click.prevent="handleCancel"
      >
        Cancelar
      </button>
    </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {ElLoading} from "element-plus";
import {clearErrors} from "@/utils/errorsUtils";

const store = useStore();
const router = useRouter();

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const parents = computed(() => store.state.parent.entities.data);
const children = computed(() => store.state.child.entities.data);
const formModel = ref(props.entity ? {
  id: props.entity.id,
  "@id": props.entity["@id"],
  administrators: props.entity.administrators,
  mainParent: props.entity.mainParent["@id"],
  secondaryParent: props.entity.secondaryParent["@id"],
  children: props.entity.children
} : {});

const rules = computed(() => {
  return {
    children: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },

    mainParent: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },

    secondaryParent: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
  formModel.value.mainParent = null
  formModel.value.secondaryParent = null
  formModel.value.children = null
  formModel.value.administrators = null
  v.$reset()
};

onMounted(() => {
  store.dispatch("child/getEntities");
  store.dispatch("parent/getEntities");
  formModel.value.mainParent = props.entity.mainParent["@id"]
  formModel.value.secondaryParent = props.entity.secondaryParent["@id"]
  formModel.value.children = []
  props.entity.children.map(item=>{
    formModel.value.children.push( item["@id"])
  })
  formModel.value.administrators = []
  props.entity.administrators.map(item=>{
    formModel.value.administrators.push(item["id"])
  })
});

const handleCancel = () => {
  router.push({ name: 'Expedients', params: {} });
};

const updateEntity = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
    await store.dispatch("expedient/edit", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Expediente editado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
        clearForm()
        await router.push({name: 'Expedients', params: {}});
      } else {
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }
    });
};

const createEntity = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
    await store.dispatch("expedient/add", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)){
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Expediente creado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        });
        await router.push({ name: 'Expedients', params: {} });
      } else {
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }

    });


};

</script>
