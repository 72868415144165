<template>
  <div class="row mt-3">
    <div class="col-md-5">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title mb-0">
            Expedientes
          </h3>
        </div>
        <div class="card-body" >
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
              <tr>
                <th
                    class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                >
                  Expediente
                </th>
                <td class="text-center"></td>
              </tr>
              </thead>

              <tbody v-if="!store.state.expedient.loading">
              <tr @click="selectExpedient(item)" v-for="item in entities" :key="item.id" :class="selectedExpedient && (item.id === selectedExpedient.id) ? 'bg-primary color-white' : ''">
                <td style="padding-left: 0!important;">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        <img v-if="!selectedExpedient" src="@/assets/img/expedients-black.svg" alt="">
                        <img v-else src="@/assets/img/expedients.svg" alt="">
                        {{ item.code }} -- {{ item.mainParent.fullName }} / {{ item.secondaryParent.fullName }}
                      </h6>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <button
                      class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                  >  <el-icon :size="25"
                              class="mr-2"
                              color="#fff">
                    <View />
                  </el-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="card-title mb-0 d-inline-block">
                Reuniones
                <span v-if="!selectedExpedient" class="text-sm text-danger">( Seleccione un expediente)</span>
                <span class="text-sm" v-else><br>Expediente {{ selectedExpedient.code }}</span>
              </h3>
            </div>
            <div class="col-md-6" v-if="selectedExpedient" style="text-align: right;padding-right: 50px;}">
               <button class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto cursor-pointer" @click="showForm()">Hacer una solicitud</button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive p-0" v-if="selectedExpedient">
            <table class="table align-items-center mb-0">
              <thead>
              <tr>
                <th class="d-table-cell d-lg-none text-uppercase font-weight-bolder">
                  Datos
                </th>
                <th class="d-lg-table-cell d-none text-uppercase font-weight-bolder">
                  Fecha
                </th>
                <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
                  Familiar
                </th>
                <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
                  Aprobada
                </th>
                <td class="d-none d-lg-table-cell text-center"></td>
              </tr>
              </thead>

              <tbody>
              <tr v-for="item in meetings" :key="item.id">
                <td class="d-none d-lg-table-cell">
                   {{ formatDate(item.scheduledAt) }}
                </td>
               <!-- <td>
                  <div class="d-none d-lg-block wrap-balance">{{ item.title }}</div>
                  <div class="d-lg-none d-table-block wrap-balance"> <b>Nombre: </b>{{ item.title }}</div>
                  <div class="d-lg-none d-table-block wrap-balance"><b>Lugar: </b>{{ item.location }}</div>
                  <div class="ml-3 d-lg-none d-table-block"><b>Categoría: </b>{{ item.category.name }}</div>
                  <div class="ml-3 d-lg-none d-table-block"><b>Fecha de inicio: </b>{{ formatDate(item.dateTime) }}</div>
                  <div class="ml-3 d-lg-none d-table-block"><b>Fecha de fin: </b>{{ formatDate(item.endDateTime) }}</div>
                </td>-->
                <td class="d-none d-lg-table-cell">
                  {{ item.parentUser.fullName }}
                </td>
                <td class="d-none d-lg-table-cell">
                  <span v-if="item.approved"> Si </span><span v-else>No</span>
                </td>
                <td class="d-none d-lg-table-cell text-center">
                 <!-- <router-link
                      class="btn btn-link text-dark mb-0 rounded icon-primary"
                      :to="{
                            name: 'Actualizar-Meeting',
                            params: { id: item.id },
                          }"
                  > <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                    <View />
                  </el-icon>Editar
                  </router-link> -->
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog width="30%" v-model="showRequestForm" title="Crear Solicitud">
    <RequestForm action="create"
                :entity="formModel"
                :expedient="selectedExpedient"
                 @close-form="closeForm"
                 @update-data="updateData"
               />
  </el-dialog>
</template>

<script setup>
import {computed, defineProps, onMounted, ref} from "vue";
import { useStore } from "vuex";
import moment from "moment/moment";
import RequestForm from "@/views/meeting/RequestForm.vue";
import {View} from "@element-plus/icons-vue";
import {ElLoading} from "element-plus";

const store = useStore();
defineProps({
  hasTitle: {
    type: Boolean,
    required: false,
    default: true
  }
})
const selectedExpedient = ref()
const formModel = ref({})
const showForm = () => {
   formModel.value = {
     scheduledAt: null,
     expedient: selectedExpedient.value ? selectedExpedient.value.id : null,
     parentUser: store.getters['user/getUser'].id,
     administrator: null,
     missed: false,
     approved: false,
     completed: false,
     requests: [
       {
         reason: null,
         description: null,
         requestedAt: null
       }
     ]

  }
  showRequestForm.value = true
}

const selectExpedient = async item => {
  selectedExpedient.value = item
  await store.dispatch('expedient/getEntityById', item.id)
}
const closeForm = async () => {
  showRequestForm.value = false
  await store.dispatch('expedient/getEntityById', selectedExpedient.value.id)
}
const updateData = async () => {
  await store.dispatch('expedient/getEntityById', selectedExpedient.value.id)
}
const getExpedients = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("expedient/getEntities");
  spin.close()
};
const showRequestForm = ref(false)
const formatDate = (value) => {
  if (value) {
    return moment((value)).format('DD/MM/YYYY HH:mm')
  }

  return ''
};
onMounted(async () => {
  await store.dispatch("administratorExpedient/setLoading", true);
  await store.dispatch("expedient/setLoading", true);
  await getExpedients();
  await store.dispatch("administratorExpedient/setLoading", false);
  await store.dispatch("expedient/setLoading", false);
});

const entities = computed(
    () => store.state.expedient.entities.data
);
const meetings = computed(
    () => store.state.expedient.currentEntity ? store.state.expedient.currentEntity.meetings : []
);

</script>

<style scoped lang="scss">
.color-white {
  color: white!important;
  a, i, h6 {
    color: white!important;
    cursor: pointer!important;
  }
}
</style>